<template id="ciudades">
<div class="page-ciudades">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Administrar Ciudades" class="elevation-1 px-5 py-3">

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="agregar" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover :style="'text-align:center;'">
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_ciudad(props.row)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="eliminar(props.row)" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Ciudad</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? actualizar() : guardar()">
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Clave" v-model="model.clave" :rules="[rules.required]" @input="upperCaseInput('clave')"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Nombre" v-model="model.nombre" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? actualizar() : guardar()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
export default {
  mounted: function() {
    if (!this.verificaPermiso('t.catalogos.ciudades')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.get_ciudades();
  },
  data() {
    return {
      id_configuracion: "",
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catálogos",
          disabled: true,
          href: ""
        },
        {
          text: "Ciudades",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "clave",
        "nombre",
        "estatus",
        "actions"
      ],
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["clave", "nombre", "estatus"],
        sortable: ["clave", "nombre", "estatus"],

        headings: {
          clave: "Clave",
          nombre: "Nombre",
          estatus: "Estatus",
          actions: "Operaciones"
        },
      },
      modal: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
      },
      items: []
    }
  },
  methods: {
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    clean_model: function() {
      return {
        uuid: "",
        clave: "",
        nombre: "",
        estatus: "Activo",
      };
    },
    get_ciudades: function() {
      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {
          "type": "configuracion"
        },
        "fields": ["_id", "ciudades"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          this.id_configuracion = response.data.docs[0]._id;
          this.items = response.data.docs[0].ciudades != undefined ? response.data.docs[0].ciudades : [];
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    get_ciudad: function(item) {
      this.model = {
        uuid: item.uuid,
        clave: item.clave,
        nombre: item.nombre,
        estatus: item.estatus,
      };
      this.editar();
    },
    agregar: function() {
      this.model = this.clean_model();
      this.update = false;
      this.modal = true;
    },
    editar: function() {
      this.update = true;
      this.modal = true;
    },
    guardar: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/agregar_ciudad/" + this.id_configuracion;
        let data = {
          uuid: this.$uuid.v4(),
          clave: this.model.clave.toString().trim(),
          nombre: this.model.nombre.toString().trim(),
          estatus: this.model.estatus,
        };

        window.axios
          .post(url, data)
          .then(response => {
            if (response.data == true) {
              this.modal = false;
              this.get_ciudades();
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al guardar el registro.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      }
    },
    actualizar: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');

        let data = this.model;
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/update_ciudad/" + this.id_configuracion;

        window.axios
          .put(url, data)
          .then(response => {
            if(response.data == true) {
              this.modal = false;
              this.get_ciudades();
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },
    eliminar: function(item) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/delete_ciudad/" + this.id_configuracion;

          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .post(url, item)
            .then(response => {
              if(response.data == true) {
                this.modal = false;
                this.get_ciudades();
                window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                  color: 'success'
                });
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: response.data,
                  footer: ""
                });
              }
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });

            }).then(() => {
              window.dialogLoader.hide();
            });

        }

      });
    },
  }
}
</script>
